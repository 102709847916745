import React, { useContext } from "react";
import Layout from "../../../components/Layout/layout";
import WhyTaxiBlog from "../../../components/Blog-Detail/whyTaxi/german-blog";
import SEO from "../../../components/SEO/seo";
const WhyTaxi = () => {
	return (
		<Layout>
			<SEO
				title="Warum nehmen die Leute lieber ein Taxi als einen Bus?"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Wenn es um Reisen geht, vernachlässigen die Leute oft den Bus und entscheiden sich für das Taxi, weil es jederzeit und überall bequem und verfügbar ist."
			/>
			<WhyTaxiBlog/>
		</Layout>
	);
};

export default WhyTaxi;
